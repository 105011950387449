import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import DatePicker from "react-multi-date-picker";
import gregorian_pt_br from "react-date-object/locales/gregorian_pt_br"

import cfg from '../../utils/config'
import auth from '../../utils/auth'

pdfMake.vfs = pdfFonts.pdfMake.vfs

// const START_DATE = "2024-09-30"
// const END_DATE = "2024-10-05"

export default function RelatorioJornadaTrabalho(props) {

    const [drivers, setDrivers] = useState([])
    const [customer, setCustomer] = useState(false)
    const [driver, setDriver] = useState(false)
    const [date_in, setDate_in] = useState(false)
    const [date_out, setDate_out] = useState(false)
    const [pernoite_in, setPernoite_in] = useState(false)
    const [pernoite_out, setPernoite_out] = useState(false)
    const [pday, setPday] = useState(0)
    const [logo, setLogo] = useState("")
    const [fnDriver, setFnDriver] = useState("")
    const [company, setCompany] = useState("")
    const [data, setData] = useState({ 'j': {}, 'ws': {}, 'rs': {}, })
    const [pdfLocked, setPdfLocked] = useState(true)
    const [runReport, setRunReport] = useState(false)
    const [modal, setModal] = useState(false)
    const [macros, setMacros] = useState([])
    const [extraHoursConfig, setExtraHoursConfig] = useState({ 'sf': 8, 'tf': 8, 'qt': 8, 'qit': 8, 'sxf': 8, 'sb': 8, 'dm': 0, 'fr': 0 })
    const tableRef = useRef(null);
    const [holydays, setHolydays] = useState([])
    const [macroEvents, setMacroEvents] = useState([])
    const [newMacroEvent, setNewMacroEvent] = useState({ 'event': null, 'time': null })

    useEffect(() => {
        getInitialData()
    }, [])

    const getInitialData = () => {
        let url = cfg.base_api_url + 'work-time/'
        let token = auth.getToken()

        const config_me = { headers: { 'Authorization': token } };

        axios.get(url, config_me).then((initial) => {
            setDrivers(initial.data.drivers)
            setCustomer(initial.data.id)
            if (initial.data.events) {
                let macroEvents_ = []
                Object.entries(initial.data.events)?.forEach(([id, value]) => {
                    macroEvents_.push({ id, value })
                })

                setMacroEvents(macroEvents_)
            }
        }).catch((request) => {
            console.log(request)
        })
    }

    const showModal = (dataday, list) => {
        try {
            list = list.sort((a, b) => {
                let splitA = a.date.split(" ")
                let splitB = b.date.split(" ")
                let dateA = `${splitA[0].split("/").reverse().join("-")} ${splitA[1]}`
                let dateB = `${splitB[0].split("/").reverse().join("-")} ${splitB[1]}`
                return new Date(dateA).getTime() - new Date(dateB).getTime()
            })
        } catch (err) {
            console.error(err)
        }

        setMacros(list)
        setModal(!modal)
    }

    const generateReport = () => {
        setRunReport(true)
        let url = cfg.base_api_url + 'work-time/'
        let token = auth.getToken()

        const config_me = { headers: { 'Authorization': token } };

        let data = new FormData()
        data.append('date_in', date_in)
        data.append('date_out', date_out)
        // data.append('date_in', START_DATE)
        // data.append('date_out', END_DATE)
        data.append('customer', customer)
        data.append('driver', driver)
        data.append('pday', pday)
        if (pernoite_in)
            data.append('pernoite_in', pernoite_in)
        if (pernoite_out)
            data.append('pernoite_out', pernoite_out)

        for (let day_var in extraHoursConfig) {
            data.append(day_var, extraHoursConfig[day_var])
        }

        if (holydays != null && holydays.length > 0)
            data.append('extra_holydays', holydays)

        axios.post(url, data, config_me)
            .then((report) => {
                setData(report.data)
                setPdfLocked(false)
                setRunReport(false)
            })
            .catch((request) => {
                console.log(request)
                setRunReport(false)
            })
    }

    const generateHtml = () => {
        let html_list = []

        Object.keys(data['j']).sort().map((week) => {

            html_list.push(Object.keys(data['j'][week]).map((day, key) => {
                return data['j'][week][day]['journey'].map((dataday, key) => {
                    return (
                        <tr key={key}>
                            <td >{day.split('-')[2] + '/' + day.split('-')[1] + '/' + day.split('-')[0]} <span onClick={() => { showModal(dataday, dataday['macros']) }} className='detail-day'>?</span></td>
                            <td >{dataday['weekday']}</td>
                            <td >{dataday['vehicle']['item']['nm'].split(' - ')[0]}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['start_time_ptbr'] = e.target.textContent} >{dataday['start_time_ptbr']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['end_time_ptbr'] = e.target.textContent} >{dataday['end_time_ptbr']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['lunch'][0]['start_time_ptbr'] = e.target.textContent} >{dataday['lunch'][0]['start_time_ptbr']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['lunch'][0]['end_time_ptbr'] = e.target.textContent} >{dataday['lunch'][0]['end_time_ptbr']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['total_work_time'] = e.target.textContent} >{dataday['total_work_time']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['total_driving'] = e.target.textContent} >{dataday['total_driving']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['most_driving_time_route'] = e.target.textContent} >{dataday['most_driving_time_route']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['stop_time'] = e.target.textContent} >{dataday['stop_time']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['total_lunch'] = e.target.textContent} >{dataday['total_lunch']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['pernoite'] = e.target.textContent} >{dataday['pernoite']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['hours_extra'] = e.target.textContent} >{dataday['hours_extra']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['hours_extra_100'] = e.target.textContent} >{dataday['hours_extra_100']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['total_resting'] = e.target.textContent} >{dataday['total_resting']}</td>
                            <td onInput={(e) => data['j'][week][day]['journey'][key]['total_waiting'] = e.target.textContent} >{dataday['total_waiting']}</td>
                        </tr>
                    )
                })
            }))

            html_list.push(
                <tr key={'week_' + week} className='sub-th'>
                    <td colSpan="7">Resumo da Semana</td>
                    <td onInput={(e) => data['ws'][week]['total_work_seconds_format'] = e.target.textContent} >{data['ws'][week]['total_work_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['total_driving_seconds_format'] = e.target.textContent} >{data['ws'][week]['total_driving_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['most_driving_time_route_seconds_format'] = e.target.textContent} >{data['ws'][week]['most_driving_time_route_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['stop_time_seconds_format'] = e.target.textContent} >{data['ws'][week]['stop_time_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['total_time_lunch_seconds_format'] = e.target.textContent} >{data['ws'][week]['total_time_lunch_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['pernoite_seconds_format'] = e.target.textContent} >{data['ws'][week]['pernoite_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['hours_extra_seconds_format'] = e.target.textContent} >{data['ws'][week]['hours_extra_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['hours_extra_100_seconds_format'] = e.target.textContent} >{data['ws'][week]['hours_extra_100_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['total_resting_seconds_format'] = e.target.textContent} >{data['ws'][week]['total_resting_seconds_format']}</td>
                    <td onInput={(e) => data['ws'][week]['total_waiting_seconds_format'] = e.target.textContent} >{data['ws'][week]['total_waiting_seconds_format']}</td>
                </tr>
            )
        })

        html_list.push(
            <tr key={"final_resume"} className='sub-close'>
                <td colSpan="7">Resumo do Período</td>
                <td onInput={(e) => data['rs']['total_work_seconds_format'] = e.target.textContent} >{data['rs']['total_work_seconds_format']}</td>
                <td onInput={(e) => data['rs']['total_driving_seconds_format'] = e.target.textContent} >{data['rs']['total_driving_seconds_format']}</td>
                <td onInput={(e) => data['rs']['most_driving_time_route_seconds_format'] = e.target.textContent} >{data['rs']['most_driving_time_route_seconds_format']}</td>
                <td onInput={(e) => data['rs']['stop_time_seconds_format'] = e.target.textContent} >{data['rs']['stop_time_seconds_format']}</td>
                <td onInput={(e) => data['rs']['total_time_lunch_seconds_format'] = e.target.textContent} >{data['rs']['total_time_lunch_seconds_format']}</td>
                <td onInput={(e) => data['rs']['pernoite_seconds_format'] = e.target.textContent} >{data['rs']['pernoite_seconds_format']}</td>
                <td onInput={(e) => data['rs']['hours_extra_seconds_format'] = e.target.textContent} >{data['rs']['hours_extra_seconds_format']}</td>
                <td onInput={(e) => data['rs']['hours_extra_100_seconds_format'] = e.target.textContent} >{data['rs']['hours_extra_100_seconds_format']}</td>
                <td onInput={(e) => data['rs']['total_resting_seconds_format'] = e.target.textContent} >{data['rs']['total_resting_seconds_format']}</td>
                <td onInput={(e) => data['rs']['total_waiting_seconds_format'] = e.target.textContent} >{data['rs']['total_waiting_seconds_format']}</td>
            </tr>
        )

        return html_list

    }

    const readLogo = (elem) => {
        if (!elem.target.files || !elem.target.files[0]) return;

        const FR = new FileReader();

        FR.addEventListener("load", function (evt) {
            setLogo(evt.target.result)
        })

        FR.readAsDataURL(elem.target.files[0]);

    }

    const handleNovoEvento = (index, key, value) => {
        let macros_ = [...macros]
        let macro = macros_[index]

        for (let i = 0; i < macros_.length; i++) {
            if ("new_event" in macros_[i]) {
                macros_[i].new_event.open = false
            }
        }

        if ("new_event" in macro) {
            macro.new_event[key] = value
        } else {
            macro.new_event = { [key]: value }
        }

        macros_[index] = macro
        setMacros(macros_)
    }

    const readCompany = (elem) => {

        if (!elem.target.files || !elem.target.files[0]) return;

        const FR = new FileReader();

        FR.addEventListener("load", function (evt) {
            setCompany(evt.target.result)
        })

        FR.readAsDataURL(elem.target.files[0]);
    }

    const saveNewEvent = (index) => {
        let macro = macros[index]

        if (!macro || !driver) return

        if (newMacroEvent?.event == null || newMacroEvent?.time == null) return

        let url = cfg.base_api_url + 'macro-events/'
        let token = auth.getToken()
        const config_me = { headers: { 'Authorization': token } }

        const split = macro?.date?.split(" ")
        let date = split[0]?.split("/").reverse().join("-")
        date = `${date} ${newMacroEvent.time}`

        let data = new FormData()
        data.append("driver", driver)
        data.append("event", newMacroEvent.event)
        data.append("date", date)

        axios.post(url, data, config_me).then(res => {
            let selectedEvent = macroEvents.find((e) => e.id == newMacroEvent.event)
            let macros_ = [...macros]

            macros_.splice(index + 1, 0, {
                id: res.data.id,
                code: newMacroEvent.event,
                name: selectedEvent?.value,
                date: `${split[0]} ${newMacroEvent.time}`
            })

            macros_ = macros_.sort((a, b) => {
                let splitA = a.date.split(" ")
                let splitB = b.date.split(" ")
                let dateA = `${splitA[0].split("/").reverse().join("-")} ${splitA[1]}`
                let dateB = `${splitB[0].split("/").reverse().join("-")} ${splitB[1]}`
                return new Date(dateA).getTime() - new Date(dateB).getTime()
            })

            handleNovoEvento(index, "open", false)
            setMacros(macros_)
        }).catch(console.error)
    }

    const deleteEvent = (id, index) => {
        let macros_ = Array.from(macros)
        macros_.splice(index, 1)
        setMacros(macros_)

        let url = cfg.base_api_url + `macro-events/?id=${id}`
        let token = auth.getToken()
        const config_me = { headers: { 'Authorization': token } };

        axios.delete(url, config_me).catch(console.error)
    }

    const exportarPDF = () => {

        let prepare_data = []

        Object.keys(data['j']).sort().forEach((week) => {
            Object.keys(data['j'][week]).forEach((day, key) => {
                data['j'][week][day]['journey'].forEach((data_line, key) => {
                    prepare_data.push(
                        [
                            day.split('-')[2] + '/' + day.split('-')[1] + '/' + day.split('-')[0],
                            data_line['weekday'],
                            data_line['vehicle']['item']['nm'].split(' - ')[0],
                            data_line['start_time_ptbr'],
                            data_line['end_time_ptbr'],
                            data_line['lunch'][0]['start_time_ptbr'],
                            data_line['lunch'][0]['end_time_ptbr'],
                            data_line['total_work_time'],
                            data_line['total_driving'],
                            data_line['most_driving_time_route'],
                            data_line['stop_time'],
                            data_line['total_lunch'],
                            data_line['pernoite'],
                            data_line['hours_extra'],
                            data_line['hours_extra_100'],
                            data_line['total_resting'],
                            data_line['total_waiting'],
                        ]
                    )
                })
            })

            prepare_data.push(
                [
                    { text: 'Resumo da Semana', colSpan: 7, fillColor: '#b7b7b7' },
                    {},
                    {},
                    {},
                    {},
                    {},
                    {},
                    { text: data['ws'][week]['total_work_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['total_driving_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['most_driving_time_route_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['stop_time_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['total_time_lunch_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['pernoite_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['hours_extra_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['hours_extra_100_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['total_resting_seconds_format'], fillColor: '#b7b7b7' },
                    { text: data['ws'][week]['total_waiting_seconds_format'], fillColor: '#b7b7b7' }
                ]
            )
        })

        prepare_data.push(
            [
                { text: 'Resumo do Período', colSpan: 7, fillColor: '#d5d5d5' },
                {},
                {},
                {},
                {},
                {},
                {},
                { text: data['rs']['total_work_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['total_driving_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['most_driving_time_route_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['stop_time_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['total_time_lunch_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['pernoite_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['hours_extra_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['hours_extra_100_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['total_resting_seconds_format'], fillColor: '#d5d5d5' },
                { text: data['rs']['total_waiting_seconds_format'], fillColor: '#d5d5d5' }
            ]
        )

        let header = []
        let content = [
            {
                columns: [
                    {
                        width: '*',
                        text: 'Jornada de Trabalho',
                        margin: [10, 50, 0, 0],
                        fontSize: 18,
                        bold: true,
                    },
                    /*{
                        width: 360,
                        heigth: 100,
                        margin: [0,0,10,0],
                        alignment: 'right',
                        image: company,
                    },*/
                    (logo) ? {
                        width: 100,
                        heigth: 100,
                        image: logo,
                    } : null,
                ]
            },
            {
                text: ".",
                color: "white"
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        ["Motorista: " + drivers.filter((driver_t) => driver_t.id == driver)[0]['name'] + " - " + fnDriver],
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 2 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                    },
                }
            },
            {
                text: ".",
                color: "white"
            },
            {
                style: 'tableExample',
                color: '#444',
                fontSize: 8,
                table: {
                    widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
                    headerRows: 2,
                    // keepWithHeaderRows: 1,
                    body: [
                        [
                            { text: '\nData', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5' },
                            { text: '\nDia Semana', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5' },
                            { text: '\nPlaca', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5' },
                            { text: '\nInício Jornada', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5' },
                            { text: '\nFim Jornada', rowSpan: 2, alignment: 'center', fillColor: '#d5d5d5' },
                            { text: 'Refeição', colSpan: 2, alignment: 'center', fillColor: '#d5d5d5' },
                            {},
                            { text: 'Tempo Total', colSpan: 10, alignment: 'center', fillColor: '#d5d5d5' },
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {},
                            {},
                            {},
                            {},
                            {},
                            { text: 'Início', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Fim', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Jornada', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Em Direção', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Continua', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Parado', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Refeição', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Pernoite', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Hora Extra '+pday+'%', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Hora Extra 100%', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Descanso', alignment: 'center', fillColor: '#b7b7b7' },
                            { text: 'Espera', alignment: 'center', fillColor: '#b7b7b7' },
                        ],
                        ...prepare_data
                    ]
                }
            },
            {
                text: ".",
                color: "white"
            },
            {
                text: ".",
                color: "white"
            },
            {
                text: ".",
                color: "white"
            },
            {
                table: {
                    headerRows: 1,
                    widths: [400],
                    body: [
                        [''],
                        ['']
                    ]
                },
                layout: 'headerLineOnly'
            },
            {
                text: drivers.filter((driver_t) => driver_t.id == driver)[0]['name'],
            },
        ]
        let footer = []

        let fileDefinition = {
            pageSize: 'A3',
            pageOrientation: 'landscape',
            pageMargins: [10, 10, 10, 20],

            header: [header],
            content: [content],
            footer: [footer],
        }

        pdfMake.createPdf(fileDefinition).open()

    }

    const lancamentoManualPDF = () => {

        let prepare_data = []

        Object.keys(data['j']).sort().forEach((week) => {
            Object.keys(data['j'][week]).forEach((day, key) => {
                data['j'][week][day]['journey'].forEach((data_line, key) => {

                    for (let index = 0; index < data_line['macros'].length; index++) {
                        const element = data_line['macros'][index];
                        if (element['manual'] == true && 'driver_id' in element){
                            prepare_data.push([
                                day.split('-')[2] + '/' + day.split('-')[1] + '/' + day.split('-')[0],
                                data_line['weekday'],
                                data_line['vehicle']['item']['nm'].split(' - ')[0],
                                element['name'],
                                element['date']
                            ])
                        }
                    } 

                })
            })
        })

        let header = []
        let content = [
            {
                columns: [
                    {
                        width: '*',
                        text: 'Lançamentos Manuais - Jornada de Trabalho',
                        margin: [10, 50, 0, 0],
                        fontSize: 18,
                        bold: true,
                    },
                    /*{
                        width: 360,
                        heigth: 100,
                        margin: [0,0,10,0],
                        alignment: 'right',
                        image: company,
                    },*/
                    (logo) ? {
                        width: 100,
                        heigth: 100,
                        image: logo,
                    } : null,
                ]
            },
            {
                text: ".",
                color: "white"
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["*"],
                    body: [
                        ["Motorista: " + drivers.filter((driver_t) => driver_t.id == driver)[0]['name'] + " - " + fnDriver],
                    ]
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 2 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'white' : 'white';
                    },
                }
            },
            {
                text: ".",
                color: "white"
            },
            {
                style: 'tableExample',
                color: '#444',
                fontSize: 8,
                table: {
                    widths: ['*', '*', '*', '*', '*'],
                    headerRows: 1,
                    body: [
                        [
                            { text: 'Data', alignment: 'center', fillColor: '#d5d5d5' },
                            { text: 'Dia Semana', alignment: 'center', fillColor: '#d5d5d5' },
                            { text: 'Placa', alignment: 'center', fillColor: '#d5d5d5' },
                            { text: 'Macro', alignment: 'center', fillColor: '#d5d5d5' },
                            { text: 'Horário', alignment: 'center', fillColor: '#d5d5d5' },
                        ],
                        ...prepare_data
                    ]
                }
            },
            {
                text: ".",
                color: "white"
            },
            {
                text: ".",
                color: "white"
            },
            {
                text: ".",
                color: "white"
            },
            {
                table: {
                    headerRows: 1,
                    widths: [400],
                    body: [
                        [''],
                        ['']
                    ]
                },
                layout: 'headerLineOnly'
            },
            {
                text: drivers.filter((driver_t) => driver_t.id == driver)[0]['name'],
            },
        ]
        let footer = []

        let fileDefinition = {
            pageSize: 'A3',
            pageOrientation: 'landscape',
            pageMargins: [10, 10, 10, 20],

            header: [header],
            content: [content],
            footer: [footer],
        }

        pdfMake.createPdf(fileDefinition).open()

    }

    return (
        <div className="container">
            <h2>Relatório de Jornada de Trabalho</h2>
            <div className='report-config'>
                <div className='input-form'>
                    <label>Selecione o Motorista</label>
                    <select onChange={(e) => setDriver(e.target.value)} name="driver" id="driver">
                        <option>-------</option>
                        {
                            drivers.map((driver) => {
                                return <option key={driver.id} value={driver.id}>{driver.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Data Inícial</label>
                        <input onChange={(e) => setDate_in(e.target.value)} type="date" name="date_in" id="date_in" />
                    </div>
                    <div>
                        <label>Data Final</label>
                        <input onChange={(e) => setDate_out(e.target.value)} type="date" name="date_out" id="date_out" />
                    </div>
                </div>
            </div>
            <div className='report-config-adv input-form-n2'>
                <div>
                    <label>Início Pernoite</label>
                    <input onChange={(e) => setPernoite_in(e.target.value)} type="time" name="pernoite_in" id="pernoite_in" />
                </div>
                <div>
                    <label>Fim Pernoite</label>
                    <input onChange={(e) => setPernoite_out(e.target.value)} type="time" name="pernoite_out" id="pernoite_out" />
                </div>
            </div>
            <fieldset>
                <legend>Detalhes Extra do PDF</legend>
                <div className='input-form-n2'>
                    <div>
                        <label>Logo da Empresa</label>
                        <input onChange={(e) => { readLogo(e) }} type="file" id="logo" name="logo" />
                    </div>
                </div>
                {/*<div className='input-form-n2'>
                    <div>
                        <label>Imagem com a Descrição da Empresa</label>
                        <input onChange={(e) => {readCompany(e)}} type="file" id="info-company" name="info-company" />
                    </div>
                    </div>*/}
                <div className='input-form-n2'>
                    <div>
                        <label>Função do Motorista</label>
                        <input type="text" onChange={(e) => setFnDriver(e.target.value)} value={fnDriver} id="info-driver" name="info-driver" />
                    </div>
                </div>
            </fieldset>
            <fieldset>
                <legend>Configuração de Horas Extras</legend>
                <div className='input-form-n2'>
                    <div>
                        <label>Segunda-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({ ...extraHoursConfig, ...{ "sf": parseInt(e.target.value) } })} value={extraHoursConfig.sf} id="date-sf" name="date-sf" /> horas
                    </div>
                    <div>
                        <label>Terça-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({ ...extraHoursConfig, ...{ "tf": parseInt(e.target.value) } })} value={extraHoursConfig.tf} id="date-sf" name="date-sf" /> horas
                    </div>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Quarta-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({ ...extraHoursConfig, ...{ "qt": parseInt(e.target.value) } })} value={extraHoursConfig.qt} id="date-sf" name="date-sf" /> horas
                    </div>
                    <div>
                        <label>Quinta-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({ ...extraHoursConfig, ...{ "qit": parseInt(e.target.value) } })} value={extraHoursConfig.qit} id="date-sf" name="date-sf" /> horas
                    </div>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Sexta-Feira</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({ ...extraHoursConfig, ...{ "sxf": parseInt(e.target.value) } })} value={extraHoursConfig.sxf} id="date-sf" name="date-sf" /> horas
                    </div>
                    <div>
                        <label>Sábado</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({ ...extraHoursConfig, ...{ "sb": parseInt(e.target.value) } })} value={extraHoursConfig.sb} id="date-sf" name="date-sf" /> horas
                    </div>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>Domingo</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({ ...extraHoursConfig, ...{ "dm": parseInt(e.target.value) } })} value={extraHoursConfig.dm} id="date-sf" name="date-sf" /> horas
                    </div>
                    <div>
                        <label>Feriados</label>
                        <input type="text" onChange={(e) => setExtraHoursConfig({ ...extraHoursConfig, ...{ "fr": parseInt(e.target.value) } })} value={extraHoursConfig.fr} id="date-sf" name="date-sf" /> horas
                    </div>
                </div>
                <div>
                    <label>Considere feriados</label>
                    <div>
                        <DatePicker
                            style={{ "width": "100%" }}
                            multiple
                            value={holydays}
                            onChange={setHolydays}
                            locale={gregorian_pt_br}
                        />
                    </div>
                </div>
                <div className='input-form-n2'>
                    <div>
                        <label>% das horas extras em dias úteis</label>
                        <input type="number" onChange={(e) => setPday(e.target.value)} value={pday} id="percent-days" name="percent-days" />
                    </div>
                </div>
            </fieldset>
            <div className='action-buttons'>
                <button style={(runReport) ? { padding: "15px 15px 15px 40px", pointerEvents: "none" } : { padding: "15px 20px", pointerEvents: "initial" }} onClick={generateReport} className="generate-report" id="generate-report">
                    <svg style={(runReport) ? { display: "block" } : { display: "none" }} fill="#fff" width="20px" height="20px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>reload</title>
                        <path d="M0 16q0-2.784 1.088-5.312t2.912-4.384 4.384-2.912 5.344-1.088q2.784 0 5.312 1.088t4.384 2.912 2.912 4.384 1.088 5.312h2.304q0.736 0 1.28 0.416t0.8 1.024 0.16 1.28-0.64 1.184l-4.576 4.576q-0.672 0.672-1.6 0.672t-1.632-0.672l-4.576-4.576q-0.512-0.512-0.608-1.184t0.128-1.28 0.8-1.024 1.312-0.416h2.272q0-2.464-1.216-4.576t-3.328-3.328-4.576-1.216-4.608 1.216-3.328 3.328-1.216 4.576 1.216 4.608 3.328 3.328 4.608 1.216q1.728 0 3.36-0.64l3.424 3.392q-3.136 1.824-6.784 1.824-2.816 0-5.344-1.088t-4.384-2.912-2.912-4.384-1.088-5.344z"></path>
                    </svg>
                    <span>Gerar Relatório</span>
                </button>
                <input disabled={pdfLocked} className='exportar-pdf' type='button' onClick={exportarPDF} name="export-pdf" id="export-pdf" value="Exportar PDF" />
                <DownloadTableExcel
                    filename="worktime"
                    sheet="worktime"
                    currentTableRef={tableRef.current}
                >

                    <button disabled={pdfLocked} className='exportar-excel'> Gerar Excel </button>

                </DownloadTableExcel>

                <input disabled={pdfLocked} className='exportar-pdf' type='button' onClick={lancamentoManualPDF} name="export-pdf" id="export-pdf" value="Lançamentos Manuais PDF" />

            </div>

            <div className="table-overflow">
                <table ref={tableRef} className='report-table'>
                    <thead>
                        <tr>
                            <th rowSpan="2">Data</th>
                            <th rowSpan="2">Dia Semana</th>
                            <th rowSpan="2">Placa</th>
                            <th rowSpan="2">Início Jornada</th>
                            <th rowSpan="2">Fim Jornada</th>
                            <th colSpan="2">Refeição</th>
                            <th colSpan="10">Tempo Total</th>
                        </tr>
                        <tr className='sub-th'>
                            <th>Início</th>
                            <th>Fim</th>
                            <th>Jornada</th>
                            <th>Em Direção</th>
                            <th>Continua</th>
                            <th>Parado</th>
                            <th>Refeição</th>
                            <th>Pernoite</th>
                            <th>Hora Extra {pday}%</th>
                            <th>Hora Extra 100%</th>
                            <th>Descanso</th>
                            <th>Espera</th>
                        </tr>
                    </thead>
                    <tbody>
                        {generateHtml()}
                    </tbody>
                </table>
            </div>
            <div style={{ display: (modal) ? "flex" : "none" }} className='modal'>
                <div></div>
                <div>
                    <span onClick={() => { setModal(!modal) }}>X</span>
                    <div>
                        {macros.map((macro, key) => {
                            let isOpen = Boolean(macro?.new_event?.open)
                            let manual = macro?.id
                            
                            if (macro['manual'] == true){
                                if (!('driver_id' in macro)){
                                    return null
                                }
                            }

                            return (
                                <>
                                    <div key={key} className='macro-detail'>
                                        <div>
                                            <div id="key">{key + 1}</div>
                                            <div>
                                                <div>{macro['name']}</div>
                                                <span>{macro['date']}</span>
                                                {manual
                                                    ? <button id="del-event-btn" onClick={() => deleteEvent(macro?.id, key)}>Deletar</button>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        {manual ?
                                            <span style={{ opacity: "0.8", height: "fit-content" }}>
                                                *Adicionado manualmente
                                            </span>
                                            : null
                                        }
                                    </div>
                                    {(!isOpen) ?
                                        <button id="new-event-btn" onClick={() => {
                                            handleNovoEvento(key, "open", true)
                                            setNewMacroEvent({ "event": macroEvents?.[0]?.id, "time": null })
                                        }}>
                                            Adicionar
                                        </button>
                                        :
                                        <button
                                            id="new-event-cancel"
                                            onClick={() => handleNovoEvento(key, "open", false)}
                                            style={{ background: "#cacaca", color: "black" }}>
                                            Cancelar
                                        </button>
                                    }
                                    {(isOpen) ?
                                        <div className="new-event">
                                            <select
                                                value={newMacroEvent.event}
                                                id="new-macro-select"
                                                onChange={(e) => setNewMacroEvent((p) => ({ ...p, "event": e.target.value }))}>
                                                {macroEvents?.map(event => (
                                                    <option value={event?.id}>{event?.value}</option>
                                                ))}
                                            </select>
                                            <input type="time" value={newMacroEvent.date} onChange={(e) => setNewMacroEvent((p) => ({ ...p, "time": e.target.value }))} />
                                            <button
                                                onClick={() => saveNewEvent(key)}
                                                style={{ background: "#cacaca", color: "black" }}>
                                                Salvar
                                            </button>
                                        </div>
                                        :
                                        null
                                    }
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
